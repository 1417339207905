
<ng-container *ngIf="view == 'card'">
  <a [routerLink]="['/', locale.prefix, 'program', program.program_id]">
    <div class="card-parent  ">
      <div class="item card mt-3 mr-1 ml-1 item-card ">
        <div href="#" class="image-link">
          <img class="card-img-top img-prog" [src]="program.cover" alt="" />
          <div class="color-overlay">
            <div href="category-nav" class="card-icon item-nav">
              <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
              <div>
                <ul class="dropdown-menu custom-dropdown-menu">
                  <a
                    href="javascript:;"
                    class="dropdown-item"
                    (click)="toggleFavorite()"
                  >
                    <i class="fa-solid fa-star"></i>
                    <span *ngIf="!program.you_favored_this">{{ "Add To Favorite" | translate }}</span>
                    <span *ngIf="program.you_favored_this">{{ "Remove From Favorite" | translate }}</span>
                  </a>
                  <a
                    [routerLink]="['/', locale.prefix, 'program', program.program_id]"
                    class="dropdown-item"
                  >
                    <i class="fa fa-eye"></i>
                    <span>{{ "Program Details" | translate }}</span>
                  </a>
                </ul>
              </div>
            </div>
            <a [routerLink]="['/', locale.prefix, 'program', program.program_id]" class="play-icon">
              <i class="fas fa-play"></i>
            </a>
            <a href="javascript:;" class="card-icon" (click)="toggleFavorite()">
              <i class="fa-solid fa-star" aria-hidden="true" [ngClass]="{ 'text-danger': program.you_favored_this }"></i>
            </a>
          </div>
        </div>
        <div class="card-body pod-body">
          <h5 class="card-title">
            {{ program.title }}
          </h5>
          <p class="p-info pt-3" *ngIf="counters">
            <span class="des-color">{{ "Episodes count" | translate }}: {{ program.episode_count }}</span> 
            <span class="des-color">{{ "Seasons" | translate }}: {{ program.season_count }}</span>
          </p>
          <span *ngIf="program.broadcaster">
            {{ "Podcast by" | translate }}:
            <a [routerLink]="['/', locale.prefix, 'podcaster', program.broadcaster.broadcaster_id]" class="p">{{ program.broadcaster.title }}</a>
          </span>
          <ul class="list-inline m-0 card-ul" *ngIf="footer">
            <li class="program-icons">
              <i class="far fa-heart"></i>{{ program.favored_by }}
              <span class="comments"></span>
            </li>
           
          </ul>
        </div>
      </div>
    </div>
 
  </a>
</ng-container>

<div class="container image" >
<ng-conainer *ngIf="view == 'slider'" class="   ">
  <div class="item" style="background-image: url('{{ program.wide_cover }}')" >
    <!-- <h4>{{ 'Program' | translate }} <span></span></h4> 
        <h2>
            <a class="slider-title" [routerLink]="['/', locale.prefix, 'program', program.program_id]">
                {{ program.title }}
            </a>
        </h2>
        <p *ngIf="locale.direction == 'rtl'">
            {{ 'Episodes count' | translate }} {{ program.episode_count }} - {{'Seasons' | translate}} {{ program.season_count }}
        </p>
        <p *ngIf="locale.direction == 'ltr'">
            {{ 'Episodes count' | translate }} {{ program.episode_count }} - {{'Seasons' | translate}} {{ program.season_count }}
        </p>
        <br><br> -->
        
    <div class="slider-bottom">
      <a
        [routerLink]="['/', locale.prefix, 'program', program.program_id]"
        class="slider-play-icon" style="margin-block-start: 10px; box-shadow: 0px 0px 8px 4px #e9e9e9;"
      >
        <i class="fas fa-play"></i>
        {{ "Listen" | translate }}
      </a>
      <a href="javascript:;" class="slider-heart" (click)="toggleFavorite()">
        <i
          class="far fa-heart  heart-slider"
          tabindex="0"
          [ngClass]="{ 'text-danger':this.program.you_favored_this, 'text-white': !this.program.you_favored_this }"
        ></i>
      </a>
    </div>
  </div>
</ng-conainer>
</div>

