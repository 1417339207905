import { Component, OnInit, Inject, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ApiService } from 'src/app/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { ErrorProcessingService } from 'src/app/services/error-processing.service';
import { LocaleService } from 'src/app/services/locale.service';
import { Home, Locale, Response } from 'src/app/types';
declare var $: any;

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
	
})
export class HomeComponent implements OnInit, OnDestroy
{
	private _unsubscribeAll: Subject<any>;

	public home			   : Home   | null | boolean = null;

	public locale          : Locale | null = null;
	constructor(
		private localeService   : LocaleService,
		private authService     : AuthService,
		private errorProcessing : ErrorProcessingService,
		private api             : ApiService
	)
	{
		this._unsubscribeAll = new Subject();
	}

	ngOnInit(): void
	{
		this.localeService.locale$
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe((locale: Locale) => {
			this.locale = locale;
      localStorage.setItem("LANG",this.locale.prefix)
			this.loadData();
		});

		// this.authService.check$
		// .pipe(takeUntil(this._unsubscribeAll))
		// .subscribe((status: boolean) => {
		// 	this.loadData();
		// });
	}

    ngOnDestroy() : void
    {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

	loadData()
	{
		this.api.home(this.locale.prefix)
		.pipe(take(1))
		.subscribe((data: Response) => {
			this.home = this.errorProcessing.auto(data);
			console.log("this.home",this.home)
			$.getScript('assets/podcasti/js/custom.js');
		});
	}
}
