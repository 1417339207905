<div class="container">
  <div class="item card item-card-most">
  <div class="image-link">
    <a [routerLink]="['/', locale.prefix, 'episode', item.episode_id]">
      <img class="card-img-top slider-img" [src]="item.image" alt=""  />
    </a>
    <!-- <div class="color-overlay">
            <div href="category-nav" class="card-icon item-nav">
                <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                <div>
                    <ul class="dropdown-menu custom-dropdown-menu">
                        <a href="javascript:;" class="dropdown-item" (click)="toggleFavorite()">
                            <i class="fa-solid fa-star"></i>
                            <span *ngIf="! item.you_favored_this">{{ 'Add To Favourite' | translate }}</span>
                            <span *ngIf="item.you_favored_this">{{ 'Remove From Favorite' | translate }}</span>
                        </a>
                        <a href="javascript:;" class="dropdown-item">
                            <i class="fa fa-share-alt"></i>
                            <span>{{ 'Share' | translate }}</span>
                        </a>
                        <a [routerLink]="['/', locale.prefix, 'episode', item.episode_id]" class="dropdown-item">
                            <i class="fa fa-eye"></i>
                            <span>{{ 'Episode Details' | translate }}</span>
                        </a>
                    </ul>
                </div>
            </div>
            <a [routerLink]="['/', locale.prefix, 'episode', item.episode_id]" class="play-icon">
                <i class="fas fa-play"></i>
            </a>
            <a href="javascript:;" class="card-icon" (click)="toggleFavorite()">
                <i class="fa-solid fa-star" aria-hidden="true" [ngClass]="{'text-danger': item.you_favored_this}"></i>
            </a>
        </div> -->
  </div>

  <div class="card-body pod-body">
    <a [routerLink]="['/', locale.prefix, 'episode', item.episode_id]">
      <h5 class="card-title">
        <a [routerLink]="['/', locale.prefix, 'episode', item.episode_id]">{{
          item.title
        }}</a>
      </h5>
      <span class="one-line-details my-3"
        >{{ "Program" | translate }}:
        <a
          [routerLink]="['/', locale.prefix, 'program', item.program_id]"
          class="p  my-5"
          >{{ item.program_title }}</a
        ></span
      >
      <ul class="list-inline m-0 card-ul">
        <!-- <li>
                <i class="fas fa-headphones"></i>{{ item.listened_by }} <span class="comments"></span>
            </li> -->
        <!-- <li>
                <i class="far fa-heart"></i>{{ item.favored_by }} <span class="comments"></span>
            </li> -->
        <li >
          <i class="far fa-clock "></i>{{ item.duration }}
          <span class="comments"></span>
        </li>
      </ul>
    </a>
  </div>
</div>
</div>

