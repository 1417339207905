<div class="container">
    <section class="breadcrumb-page breadcrumb-page-pb-120">
    <div class="sub-header">
        <h3>{{ 'Contact Us' | translate }}</h3>
        <ol class="breadcrumb">
            <li>
                <a href="javascript:;" [routerLink]="['/', locale.prefix]"> {{ 'Home' | translate }} / </a>
            </li>
            <li>
                <a href="javascript:;">&nbsp; {{ 'Send Us a Message' | translate }} </a>
            </li>
        </ol>
    </div>
</section>

<ng-container>

    <div class="container" style="margin-top: 100px;">
        <div class="custom-section-3">
            <div class="row" style="max-width:100%">
                <div class="col-md-12 col-xl-12 ml-3 card">
                    <h5 class="heading-title">{{ 'Contact Us' | translate }}</h5>
                    <hr class="p-underline">
                    <div class="login-form">
                        <form [formGroup]="form">
                            <div class="row">
                                <div class="input-group mb-3 col-md-6 col-xl-6 col-sm-12">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text input-group-text-2" id="name"><i class="fa fa-user" aria-hidden="true"></i></span>
                                    </div>
                                    <input name="sender" type="text" [formControlName]="'sender'" class="form-control form-control-2" placeholder="{{ 'Name' | translate }}" aria-label="name" aria-describedby="name">
                                </div>
                                <div class="input-group mb-3 col-md-6 col-xl-6 col-sm-12">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text input-group-text-2" id="email"><i class="fa fa-envelope" aria-hidden="true"></i></span>
                                    </div>
                                    <input name="email" type="text" [formControlName]="'email'" class="form-control form-control-2" placeholder="{{ 'Email' | translate }}" aria-label="email" aria-describedby="email">
                                </div>
                            </div>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text input-group-text-2" id="subject"><i class="fa fa-pencil" aria-hidden="true"></i></span>
                                </div>
                                <input name="subject" type="text" [formControlName]="'subject'" class="form-control form-control-2" placeholder="{{ 'Subject' | translate }}" aria-label="subject" aria-describedby="subject">
                            </div>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text input-group-text-2" id="message"><i class="fa fa-envelope" aria-hidden="true"></i></span>
                                </div>
                                <textarea name="message" type="text" [formControlName]="'message'" class="form-control form-control-2" placeholder="{{ 'Message' | translate }}" aria-label="Message" aria-describedby="Message" rows="5"></textarea>
                            </div>
                            <button 
                                [disabled]="form.disabled"
                                (click)="send()"
                                type="button" 
                                class="btn btn-primary btn-lg btn-block submit-btn">
                                {{ 'Submit' | translate }}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>

</ng-container>
</div>

