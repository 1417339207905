<!-- <p *ngIf="episode == null">Loading</p>
<p *ngIf="episode == false">Error</p>
<p *ngIf="episode">Loaded</p> -->

<!-- <ng-container *ngIf="episode">
    <app-player [episode]="episode"></app-player>
</ng-container> -->
<div class="container">
    <section class="breadcrumb-page">
  <div class="sub-header">
      <h3>{{'Episode' | translate}}</h3>
      <ol class="breadcrumb">
          <li>
              <a href="javascript:;" [routerLink]="['/', locale.prefix]"> {{ 'Home' | translate }} / </a>
          </li>
          <ng-container *ngIf="episode">
              <li>
                  <a href="javascript:;" [routerLink]="['/', locale.prefix, 'program', episode.program_id]">&nbsp; {{ episode.program_title }} / </a>
              </li>
              <li>
                  <a href="javascript:;">&nbsp; {{ episode.title }}</a>
              </li>
          </ng-container>
      </ol>
  </div>
</section>

<ng-container *ngIf="episode">
    <section >
        <div class="row ">
            <div class="col-12 col-md-9 " >
                <h3 style="font-weight: bold;color: #524779;text-align: start;" class="mb-3 Podcaster-tilt"> {{'Episode Details' | translate}}</h3>
                
                <p class="justified-text">
                    {{ episode.description }}
                </p>
            </div>
            <div class="col-md-3">
                <h3 style="text-align: center;font-weight: bold;color: #524779; " class="mb-4 Podcaster-tilt"> {{'Podcaster' | translate}} </h3>

                <app-episode-podcaster  [broadcaster]="episode.broadcaster" [locale]="locale"></app-episode-podcaster>
            </div>
        </div>
    </section>
  <app-player
      [locale]="locale"
      
      [episode]="episode"
      (onStateChanged)="onStateChanged($event)"
      (onManuelStateChanged)="onManuelStateChanged($event)">
  </app-player>


</ng-container>

</div>
