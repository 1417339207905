<div class="container">
    <section class="breadcrumb-page breadcrumb-page pb-120">
    <div class="sub-header">

        <ol class="breadcrumb">
            <li>
                <a href="javascript:;" [routerLink]="['/', locale.prefix]"> {{ 'Home' | translate }} / </a>
            </li>
            <li>
                <a href="javascript:;" [routerLink]="['/', locale.prefix, 'categories']">&nbsp; {{'Categories' | translate }} /</a>
            </li>
            <li *ngIf="category">
                <a href="javascript:;">&nbsp; {{ category.title }}</a>
            </li>
        </ol>
    </div>
</section>



<section class="custom-section mt-5" *ngIf="category" >
    <div class="">
        <div class="row " style="max-width:100%">
            <ng-container *ngFor="let item of category.programs.list">
                <div class="col-md-3 col-12">
                    <app-program-card [program]="item" [locale]="locale"></app-program-card>
                </div>
            </ng-container>
        </div>
    </div>
</section>

<div class="col-lg-12 col-md-12 col-12" *ngIf="most_listened">
    <h2 class="main-title">
        {{'Most Listened' | translate}}
    </h2>
    <div class="owl-carousel category-slider category-items owl-theme">
        <ng-container *ngFor="let item of most_listened | slice:0:5">
            <app-program-card [program]="item" [locale]="locale"></app-program-card>
        </ng-container>
    </div>
</div>

</div>
