
<section class="breadcrumb-page">
    <div class="sub-header">
        <h3>{{ 'Search' | translate }}</h3>
        <ol class="breadcrumb">
            <li>
                <a href="javascript:;" [routerLink]="['/', locale.prefix]"> {{ 'Home' | translate }} / </a>
            </li>
            <li>
                <a href="javascript:;">&nbsp; {{'Episodes' | translate}}</a>
            </li>
        </ol>
    </div>
</section>

<section class="custom-section" *ngIf="episodes">
    <div class="">
        <div class="row " style="max-width:100%">
            <ng-container *ngFor="let item of episodes">
                <div class="col-md-3 col-12">
                    <app-vertical-episode [item]="item" [locale]="locale"></app-vertical-episode>
                </div>
            </ng-container>
        </div>
    </div>
</section>

<div class="container">
    <div class="row">
        <div class="col-md-12 text-center" *ngIf="hasMorePages">
            <button class="load-more" type="button" (click)="nextPage()">{{ 'Load More' | translate }}</button>
        </div>
    </div>
</div>