import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ApiService } from 'src/app/api.service';
import { ErrorProcessingService } from 'src/app/services/error-processing.service';
import { LocaleService } from 'src/app/services/locale.service';
import { Episode, Locale, Response } from 'src/app/types';
declare var $: any;

@Component({
	selector: 'app-search',
	templateUrl: './search.component.html',
	styleUrls: ['./search.component.scss'],
	
})
export class SearchComponent implements OnInit, OnDestroy
{
	private _unsubscribeAll: Subject<any>;

	public locale          : Locale    | null = null;

	public episodes		   : Episode[] | null = null;

	public page            : number 		  = 1;

	public hasMorePages    : boolean 		  = false;

	public keyword         : string 		  = null;

	constructor(
		private localeService    : LocaleService, 
		private errorProcessing  : ErrorProcessingService, 
		private apiService	     : ApiService,
		private activatedroute   : ActivatedRoute
	) 
	{ 
		this._unsubscribeAll = new Subject();
	}

	ngOnInit(): void 
	{
		this.activatedroute.paramMap
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe((params) => { 
			this.keyword = params.get('keyword');
			this.localeService.locale$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((locale: Locale) => {
				this.locale   = locale;
				this.page     = 1;
				this.episodes = [];
				this.loadData(locale);
			});
		});
	}

    ngOnDestroy() : void
    {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

	nextPage()
	{
		this.page++;
		this.loadData(this.locale);
	}

	loadData(locale: Locale)
	{
		this.apiService.search(locale.prefix, {
			page   : this.page,
			search : this.keyword,
		})
		.pipe(take(1))
		.subscribe((data: Response) => {
			let _data = this.errorProcessing.auto(data);
			if (_data) {
				this.episodes = this.episodes ? this.episodes : [];
				_data.list.forEach((element) => {
					this.episodes.push(element);
				});
				this.hasMorePages = _data.has_more_pages;
				$.getScript('assets/podcasti/js/custom.js');
			}
		});
	}
}