<div class="container">
  <section class="breadcrumb-page">
    <div class="sub-header">
      
        <ol class="breadcrumb">
            <li>
                <a href="javascript:;" [routerLink]="['/', locale.prefix]"> {{ 'Home' | translate }} / </a>
            </li>
            <li> 
                <a href="javascript:;">&nbsp; {{ 'Categories' | translate }}</a>
            </li>
        </ol>
    </div>
</section>

<ng-container *ngIf="categories">
    <section class="custom-section" style="margin-top: 50px ;">
        <div class="">
            <div class="row" style="max-width:100%">
                <ng-container *ngFor="let item of categories">
                    <div class="col-md-3 col-12">
                        <app-category-card [category]="item" [locale]="locale"></app-category-card>
                    </div>
                </ng-container>
            </div>
        </div>
    </section>
</ng-container>
  
</div>



