<!-- <div class="item card mb-3 custom-card-r">
    <a [routerLink]="['/', locale.prefix, 'category', category.category_id]" class="image-link">
        <img class="card-img-top hover-shadow" [src]="category.image" alt="">
    </a>
</div> -->
<div class="item card mb-3 custom-card-r">
    <a [routerLink]="['/', locale.prefix, 'category', category.category_id]" class="image-link category-image hover-shadow" style="background:url('{{ category.image }}')">
        <h4>{{'App Name' | translate}}</h4>
        <h3>{{ category.title }}</h3>
    </a>
</div>