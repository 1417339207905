<section class="breadcrumb-page breadcrumb-page-pb-120">
    <div class="sub-header">
        <h3>{{ 'Account' | translate }}</h3>
        <ol class="breadcrumb">
            <li>
                <a href="javascript:;" [routerLink]="['/', locale.prefix]"> {{ 'Home' | translate }} / </a>
            </li>
            <li>
                <a href="javascript:;">&nbsp; {{ 'Update Account' | translate }} </a>
            </li>
        </ol>
    </div>
</section>

<ng-container *ngIf="user">

    <div class="container ">
        <div class="custom-section-3">
            <div class="row m-5" style="max-width:100%">
                <div class="col-md-3 col-xl-3 card h-f mt-5">
                    <h5 class="heading-title">{{ 'Settings' | translate }}</h5>
                    <hr class="p-underline">
                    <ul class="list-unstyled account-list">
                        <li><a [routerLink]="['/', locale.prefix, 'profile']" class="py-1 d-block">{{ 'Update Account' | translate }}</a></li>
                        <li><a [routerLink]="['/', locale.prefix, 'profile', 'security']" class="py-1 d-block">{{ 'Change Password' | translate }}</a></li>
                    </ul>
                </div>
                <div class="col-md-8 col-xl-8 ml-3 card mt-5">
                    <h5 class="heading-title">{{ 'Update Account' | translate }}</h5>
                    <hr class="p-underline">
                    <div class="login-form">
                        <form [formGroup]="form">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text input-group-text-2" id="Name">
                                        <i class="far fa-user"></i>
                                    </span>
                                </div>
                                <input name="name" type="text" [formControlName]="'name'" class="form-control form-control-2" placeholder="Name" aria-label="Name" aria-describedby="Name">
                            </div>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text input-group-text-2" id="email"><i class="fa fa-envelope" aria-hidden="true"></i></span>
                                </div>
                                <input name="email" type="text" [formControlName]="'email'" class="form-control form-control-2" placeholder="Email" aria-label="Email" aria-describedby="email">
                            </div>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text input-group-text-2" id="Sex">
                                        <i class="far fa-user"></i>
                                    </span>
                                </div>
                                
                                <select name="sex" [formControlName]="'sex'" class="form-control form-control-2 select-sex">
                                    <option value="UNSPECIFIED">{{ 'UNSPECIFIED' | translate }}</option>
                                    <option value="MALE">{{ 'MALE' | translate }}</option>
                                    <option value="FEMALE">{{ 'FEMALE' | translate }}</option>
                                </select>
                            </div>
                            <button 
                                [disabled]="form.disabled"
                                (click)="updateProfile()"
                                type="button" 
                                class="btn btn-primary btn-lg btn-block submit-btn">
                                {{ 'Submit' | translate }}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>

</ng-container>