<div class="container">
<div class="container">
   <div *ngFor="let blog of blogData" class="card">
    <h4>{{locale.name=='Arabic'? blog.title_ar:blog.title_en }}</h4>
  <br>
  <p>{{ locale.name=='Arabic'?  blog.content_ar:blog.content_en }}</p>
  </div>
</div>
   
</div>







