<div class="container">
    <div class="row ">    
        <div class="col-md-12 col-12 color-logo">
            <a [routerLink]="['/', locale.prefix]">
                <img src="assets/podcasti/images/color-logo.png" alt="">
            </a>
        </div>
        <div class="col-md-6 col-12 form-control form-control-2 signup-main" style="z-index: 1; display: inline-table;">
            <h5 class="main-title-3">
                {{ 'New account' | translate }}
                <span>{{ 'Create new account' | translate }}</span>
            </h5>
            <div class="login-form">
                <form [formGroup]="form">
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text input-group-text-2" id="name"><i class="fa fa-user" aria-hidden="true"></i></span>
                        </div>
                        <input name="name" type="name" [formControlName]="'name'" class="form-control form-control-2" placeholder="{{ 'Name' | translate }}" aria-label="Name" aria-describedby="Name">
                    </div>

                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text input-group-text-2" id="email"><i class="fa fa-envelope" aria-hidden="true"></i></span>
                        </div>
                        <input name="email" type="email" [formControlName]="'email'" class="form-control form-control-2" placeholder="{{ 'Email' | translate }}" aria-label="Email" aria-describedby="email">
                    </div>

                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text input-group-text-2" id="password"><i class="fa fa-lock" aria-hidden="true"></i></span>
                        </div>
                        <input name="password" type="password" [formControlName]="'password'" class="form-control form-control-2" placeholder="{{ 'Password' | translate }}" aria-label="Password" aria-describedby="password">
                    </div>

                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text input-group-text-2" id="password"><i class="fa fa-lock" aria-hidden="true"></i></span>
                        </div>
                        <input name="password" type="password" [formControlName]="'password_confirmation'" class="form-control form-control-2" placeholder="{{ 'Password Confirmation' | translate }}" aria-label="Password Confirmation" aria-describedby="Password Confirmation">
                    </div>

                    <button 
                        [disabled]="form.disabled"
                        (click)="signUp()"
                        type="button" 
                        class="btn btn-primary btn-lg btn-block submit-btn">
                        {{ 'Submit' | translate }}
                    </button>
                </form>
                <div class="social-login">
                    <span class="text-left sign-text">{{ 'Register Using' | translate }}</span>
                    <div class="text-right sign-social-media">
                        <!-- <a href="javascript:;" (click)="loginWithFacebook()">
                            <img src="assets/podcasti/images/facebook.svg" alt="" width="70px">
                        </a> -->
                        <a href="javascript:;" (click)="loginWithGoogle()">
                            <img src="assets/podcasti/images/google.svg" alt="" width="70px">
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-12 p-0">
            <div class="login-image">
                <img src="assets/podcasti/images/background.png" alt="">
                <div class="has-account">
                    {{ 'Has Account?' | translate }}
                    <a [routerLink]="['/', locale.prefix, 'account', 'sign-in']">{{ 'Sign in' | translate }}</a>
                </div>
            </div>
        </div>
    </div>
</div>