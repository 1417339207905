<!-- <button *ngIf="! isPlaying" (click)="toggleState()">play</button>
<button *ngIf="isPlaying"  (click)="toggleState()">pause</button>

<button (click)="seekForward()">+10</button>
<button (click)="seekBackward()">-10</button>

<button>{{ currentTimeInSeconds }}</button>
<button>{{ duration }}</button>

<button (click)="changePlayBackRate()" [disabled]="! canSetPlaybackRate">X{{ currentPlaybackRate }}</button> -->

<section class="main-play mt-5">
    <div class="player-container" style="margin: auto;" >
        <div class="row">
            <div class="col-md-12" style="margin-top: 90px;">
                <img [src]="episode.image" alt="" style=" height: auto;" class="img-podcaster">
                <div class="player-top-head">

                    <span class="heart"><i class="far fa-heart"></i>&nbsp; {{ episode.favored_by }}</span>
                </div>
            </div>

            <div class="col-md-10 " style="text-align: center;margin: auto;margin-bottom: 90px;">


                <h3 style="text-align: center;" class="mt-4">{{ episode.title }}</h3>
                <p style="text-align: center;"> {{ episode.program_title }}</p>
                <p style="text-align: center; " class="mb-2">{{ duration }}</p>
                <div class="row" >

                    <div class="col-md-8"style="text-align: center;margin :auto">
                        <div class="item">
                            <!-- <span class="player-counter">{{ currentTimeInSeconds }}</span> -->
                            <vm-player dir="rtl" #player cross-origin="true" [currentTime]="currentTime"
                                (vmCurrentTimeChange)="CurrentTimeChange($event)"
                                (vmDurationChange)="setDuration($event)" (vmPausedChange)="stateChanged($event)"
                                (vmPlaybackReady)="PlaybackReady($event)" (vmSeeked)="onSeek($event)"
                                (vmPlaybackReady)="onReady($event)" >
                                <vm-audio>
                                    <source data-src="{{ episode.link }}" type="audio/mp3" />
                                </vm-audio>
                                <vm-controls >
                                    <vm-scrubber-control [hideTooltip]="true " ></vm-scrubber-control>
                                    <vm-live-indicator></vm-live-indicator>
                                </vm-controls>
                            </vm-player>
                            <!-- <span class="player-counter">{{ duration }}</span> -->
                        </div>
                    </div>
                    <!--  <div class="col-md-1 p-0">
                        <div class="item item2">
                           <a href="javascript:;" class="player-share-icons">
                                <i class="fi-rr-share"></i>
                            </a>
                            &nbsp;&nbsp;&nbsp;&nbsp; 
                        
                            &nbsp;&nbsp;&nbsp;&nbsp;
                        </div>
                    </div>-->
                </div>
                <div class="row text-center justify-content-center flex-nowrap">
                    <div class="item col-auto item-icon">
                        <a href="javascript:;" class="player-fast " (click)="changePlayBackRate()">
                            x{{ currentPlaybackRate }}
                        </a>
                    </div>
                    <ng-container *ngIf="locale.direction == 'ltr'">
                        <div class="item col-auto   player-forward" (click)="seekBackward()">
                            <i class="fas fa-reply" style="color: #542779;"></i>
                            <div class="number">10s</div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="locale.direction == 'rtl'">
                        <div class="item col-auto   player-forward" (click)="seekForward()">
                            <i class="fas fa-share" style="color: #542779;"></i>
                            <div class="number">10s</div>
                        </div>
                    </ng-container>
                    <div class="item col-auto  ">
                        <a href="javascript:;" class="player-play-icon" *ngIf="!isPlaying" (click)="toggleState()">
                            <i class="fas fa-play"></i>
                        </a>
                        <a href="javascript:;" class="player-play-icon" *ngIf="isPlaying" (click)="toggleState()">
                            <i class="fas fa-pause"></i>
                        </a>
                    </div>
                    <ng-container *ngIf="locale.direction == 'rtl'">
                        <div class="item col-auto   player-forward " (click)="seekBackward()">
                            <i class="fas fa-reply" style="color: #542779;"></i>
                            <div class="number">10s</div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="locale.direction == 'ltr'">
                        <div class="item col-auto  player-forward" (click)="seekForward()">
                            <i class="fas fa-share" style="color: #542779;"></i>
                            <div class="number">10s</div>
                        </div>
                    </ng-container>
                    <div class="item col-auto  ">
                        <a href="javascript:;" class="player-share-icons px-2 item-icon" (click)="toggleFavorite()">
                            <i class="far fa-heart" [ngClass]="{'text-danger': episode.you_favored_this}"></i>
                        </a>
                    </div>
                </div>
                
                
                <!-- <span class="p-title">
                    <svg xmlns="http://www.w3.org/2000/svg" height="22px" viewBox="0 0 24 24" width="22px" fill="#B5B5B5">
                        <path d="M0 0h24v24H0V0z" fill="none"/>
                        <path d="M19 14v3c0 .55-.45 1-1 1h-1v-4h2M7 14v4H6c-.55 0-1-.45-1-1v-3h2m5-13c-4.97 0-9 4.03-9 9v7c0 1.66 1.34 3 3 3h3v-8H5v-2c0-3.87 3.13-7 7-7s7 3.13 7 7v2h-4v8h3c1.66 0 3-1.34 3-3v-7c0-4.97-4.03-9-9-9z"/>
                    </svg>
                    &nbsp; 
                </span> -->
            </div>

        </div>
    </div>

</section>